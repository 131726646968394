<template>
	<div>
		<div class="row">
			<div class="col-md-12 mr-auto ml-auto" v-if="costRegistrationLoaded">
				<card :title="$t('sidebar.costReport')">
					<base-input>
						<el-input
							type="search"
							class="mb-3 search-input from-group"
							clearable
							prefix-icon="el-icon-search"
							:placeholder="$t('common.searchRecords')"
							v-model="searchQuery"
							aria-controls="datatables"
						>
						</el-input>
					</base-input>
					<div class="calendar d-flex align-items-center">
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.from')"
								v-model="fromDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
						<span class="mx-2">-</span>
						<div class="d-flex align-items-center" style="flex: 1;">
							<el-date-picker
								class="flex-grow-1 form-group"
								type="date"
								:placeholder="$t('common.to')"
								v-model="toDate"
								@change="fetchCostRegistration"
							>
							</el-date-picker>
						</div>
					</div>
					<template v-if="isMobile">
						<div v-for="(project, projectId) in groupedReservations" :key="projectId">
							<collapse v-model="activeNames">
								<collapse-item :name="projectId">
									<template #title class="collapse-title">
										<span class="project-title">{{ project.name }}</span>
										<span class="project-details"> {{ $t('common.totalAmount') }} án vsk <b>{{ (project.totalRevenue/1.24).toFixed(2) }} kr</b>, m/vsk: <b>{{ project.totalRevenue }}</b> kr </span>
									</template>
									<div v-for="(tool, toolId) in project.tools" :key="toolId">
										<p>{{ tool.reservations[0].tool.name }}</p>
										<div v-for="reservation in tool.reservations" :key="reservation.id" class="item-tool">
											<span class="newline">
												{{ new Date(reservation.reservedTimestamp).toLocaleDateString() }} - {{ new Date(reservation.unreservedTimestamp).toLocaleDateString() }}
											</span>
											<span class="newline" v-if="reservation.externalUser">
												{{ $t('tools.rented') }} {{$t('tools.loanedRentedTo')}} {{ reservation.externalUser }}
											</span>
											<span class="newline">
												<i>{{ reservation.totalPrice == null ? 0 : reservation.totalPrice }} kr</i>
											</span>
										</div>
									</div>
									<base-button class="download-button" type="standard" @click="generatePDF(project)">
										{{ $t('common.download') }}
									</base-button>
								</collapse-item>
							</collapse>
						</div>
					</template>
					<template v-if="!isMobile">
						<div v-for="(project, projectId) in groupedReservations" :key="projectId">
							<collapse v-model="activeNames">
								<collapse-item :title="`${project.name} - ${$t('common.totalAmount')} án vsk ${(project.totalRevenue/1.24).toFixed(2)} kr, m/vsk: ${project.totalRevenue} kr`" :name="projectId">
									<div v-for="(tool, toolId) in project.tools" :key="toolId" class="item-tool">
										{{ tool.reservations[0].tool.name }}
										<div v-for="reservation in tool.reservations" :key="reservation.id" class="item-tool-dates">
											{{ new Date(reservation.reservedTimestamp).toLocaleDateString() }} - 
											{{ new Date(reservation.unreservedTimestamp).toLocaleDateString() }}
											<span v-if="reservation.externalUser">
												: {{ $t('tools.rented') }} {{$t('tools.loanedRentedTo')}} {{ reservation.externalUser }}
											</span>
											<span class="item-tool-dates-amount">
												{{ reservation.totalPrice == null ? 0 : reservation.totalPrice }} kr
											</span>
										</div>
									</div>
									<base-button class="download-button" type="standard" @click="generatePDF(project)">
										{{ $t('common.download') }}
									</base-button>
								</collapse-item>
							</collapse>
						</div>
					</template>
				</card>
			</div>
		</div>
	</div>
</template>
<script>

import api from "../../services/api"
import { DatePicker } from 'element-ui';
import { Collapse, CollapseItem } from 'src/components';
import jsPDF from 'jspdf';

export default {
	components: {
		[DatePicker.name]: DatePicker,
		Collapse,
    	CollapseItem
	},
	data() {
		return {
			activeNames: ['1'],
			toDate: null,
			fromDate: null,
			reservationData: [],
			customerInformation: {},
			costRegistrationLoaded: false,
			searchQuery: '',
			isMobile: window.innerWidth <= 768
		};
	},
	computed: {
		groupedReservations() {
			let grouped = this.reservationData.reduce((grouped, reservation) => {
				let projectId = reservation.project?.id || "reservationsNotLinkedToProject";
				let toolId = reservation.tool.id;
				if (!grouped[projectId]) {
					grouped[projectId] = {};
				}
				if (!grouped[projectId][toolId]) {
					grouped[projectId][toolId] = [];
				}
				grouped[projectId][toolId].push(reservation);
				return grouped;
			}, {});

			for (let projectId in grouped) {
				let projectName = null;
				let projectKey = null;
				let projectTotalRevenue = 0;
				let tools = [];

				for (let toolId in grouped[projectId]) {
					let reservations = grouped[projectId][toolId];
					projectName = reservations[0].project ? reservations[0].project.name : this.$t('tools.rented');
					projectKey = reservations[0].project ? reservations[0].project.key : '';
					let totalRevenue = reservations.reduce((total, reservation) => total + reservation.totalPrice, 0);
					tools.push({
					toolId: toolId,
					reservations: reservations,
					totalRevenue: totalRevenue
					});
					projectTotalRevenue += totalRevenue;
				}

				grouped[projectId] = {
					tools: tools,
					totalRevenue: projectTotalRevenue,
					name: projectName,
					key: projectKey
				};
			}

			let sortedGrouped = Object.keys(grouped)
				.sort((a, b) => { 
					if (a === "reservationsNotLinkedToProject") return 1;
					if (b === "reservationsNotLinkedToProject") return -1;
					return grouped[a].name.localeCompare(grouped[b].name);
				})
				.reduce((sorted, key) => { sorted[key] = grouped[key]; return sorted; }, {});

			// Filter based on search query
			if (this.searchQuery) {
				sortedGrouped = Object.fromEntries(
					Object.entries(sortedGrouped).filter(([key, value]) => {
						if (value.name) {
							return value.name.toLowerCase().includes(this.searchQuery.toLowerCase());
						}
					})
				);
			}
			return sortedGrouped;
		}
	},
	created() {
		window.addEventListener('resize', this.updateIsMobile);
		this.initializeDateRange();
		this.fetchCostRegistration();
		this.fetchCustomerInformation();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.updateIsMobile);
	},
	methods: {
		initializeDateRange() {
			const today = new Date();
			this.toDate = today;
			this.fromDate = new Date(today.getFullYear(), today.getMonth(), 1);
		},
		fetchCostRegistration() {
			const fetchReservations = async (url) => {
				let allReservations = [];
				let currentPage = 1;
				let totalPages = 1;

				while (currentPage <= totalPages) {
					const response = await api.get(`${url}&currentPage=${currentPage}`);
					const data = response.data;

					allReservations = allReservations.concat(data.result.filter(item => item.totalPrice !== 0 && item.totalPrice !== null));

					currentPage = data.currentPage + 1;
					totalPages = data.totalPages;
				}
				return allReservations;
			};
			
			let start = this.formatDate(this.fromDate);
			let end = this.formatDate(this.toDate);
			const reservationUrl = `/Tool/Reservations?forCurrentUser=false&unreserved=true&fromDate=${start}&toDate=${end}&pageSize=100`;
			const externalReservationUrl = `/Tool/Reservations/External?forCurrentUser=false&unreserved=true&fromDate=${start}&toDate=${end}&pageSize=100`;
			Promise.all([fetchReservations(reservationUrl), fetchReservations(externalReservationUrl)])
				.then(([internalReservations, externalReservations]) => {
					this.reservationData = internalReservations.concat(externalReservations.filter(item => item.rented));
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: `Unable to fetch reservations`,
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 0,
					});
				})
				.finally(() => {
					this.costRegistrationLoaded = true;
				});
		},
		formatDate(date) {
			let d = new Date(date);
			let year = d.getFullYear();
			let month = String(d.getMonth() + 1).padStart(2, '0');
			let day = String(d.getDate()).padStart(2, '0');

			return `${year}-${month}-${day}`;
		},
		fetchCustomerInformation() {
			let customerUrl = `/Customers/Me`
			api.get(customerUrl)
				.then(response => {
					this.customerInformation = response.data.value;
				})
				.catch(error => {
					console.error('API request error:', error);
					this.$notify({
						message: this.$t('common.unableToRetrieveData'),
						icon: "tim-icons icon-alert-circle-exc",
						horizontalAlign: "center",
						verticalAlign: "top",
						type: "danger",
						timeout: 2000,
					});
				});
		},
		generatePDF(project) {
			const doc = new jsPDF();
			doc.setFont('times', 'normal');
			const textXPosition = 60;

			// This whole if else if crap should be revisited, is a bit retarded how I am doing this now tbh
			if (this.customerInformation.logoBytes === null && this.customerInformation.identificationNumber === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(project.name, textXPosition, 30);
				doc.text(project.key, textXPosition, 40);
				generatePdfBody(project, doc);
			}
			else if (this.customerInformation.logoBytes === null) {
				// Add company Name, SSN and a header line
				doc.setFontSize(14);
				// doc.setTextColor("#2d3a5e");
				doc.text(this.customerInformation.name, textXPosition, 20);
				doc.setFontSize(12);
				doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
				doc.setFontSize(10);
				doc.text(project.name, textXPosition, 40);
				doc.text(project.key, textXPosition, 50);
				this.generatePdfBody(project, doc);
			}
			else if (this.customerInformation.identificationNumber === null) {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					
					// THIS IS USED IF WE ONLY WANT TO RESIZE THE IMAGE IF IT EXCEEDS THE 50PX BOUNDARY IN EITHER HEIGHT OR WIDTH
					let width = 50;
					let height = 50;
					// Get the actual dimensions of the image
					const imgWidth = img.width;
					const imgHeight = img.height; 
					// Calculate the scaling factor to maintain aspect ratio 
					if (imgWidth > width || imgHeight > height) {
						const widthScale = width / imgWidth;
						const heightScale = height / imgHeight;
						const scale = Math.min(widthScale, heightScale);
						width = imgWidth * scale;
						height = imgHeight * scale;
					}
					doc.addImage(logoDataUrl, 'PNG', 10, 10, width, height); 
					

					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					// const targetSize = 50;
					// const imgWidth = img.width;
					// const imgHeight = img.height;
					// // Calculate the scaling factor to maintain aspect ratio
					// const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// // Calculate the new width and height to maintain aspect ratio 
					// const width = imgWidth * scale;
					// const height = imgHeight * scale; 
					
					// // Calculate the positions to center the image if it's smaller than 50x50 
					// const xOffset = (targetSize - width) / 2;
					// const yOffset = (targetSize - height) / 2;
					// // Add the image with the calculated dimensions, centered within the 50x50 box 
					// doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(project.name, textXPosition, 30);
					doc.text(project.key, textXPosition, 40);
					this.generatePdfBody(project, doc);
				}
				
			}
			else {
				const logoDataUrl = 'data:image/png;base64,' + this.customerInformation.logoBytes;
				// Create an Image object
				const img = new Image();
				img.src = logoDataUrl;
				img.onload = () => {
					let width = 50;
					let height = 50;
					// Get the actual dimensions of the image
					const imgWidth = img.width;
					const imgHeight = img.height; 
					// Calculate the scaling factor to maintain aspect ratio 
					if (imgWidth > width || imgHeight > height) {
						const widthScale = width / imgWidth;
						const heightScale = height / imgHeight;
						const scale = Math.min(widthScale, heightScale);
						width = imgWidth * scale;
						height = imgHeight * scale;
					}
					doc.addImage(logoDataUrl, 'PNG', 10, 10, width, height); 
					
					// This maintains the aspect ratio without skewing the image while forcing it to fit into 50x50
					// Image that is smaller than 50x50 will be centered within the box 
					// const targetSize = 50;
					// const imgWidth = img.width;
					// const imgHeight = img.height;
					// // Calculate the scaling factor to maintain aspect ratio
					// const scale = Math.min(targetSize / imgWidth, targetSize / imgHeight);
					// // Calculate the new width and height to maintain aspect ratio 
					// const width = imgWidth * scale;
					// const height = imgHeight * scale; 
					
					// // Calculate the positions to center the image if it's smaller than 50x50 
					// const xOffset = (targetSize - width) / 2;
					// const yOffset = (targetSize - height) / 2;
					// // Add the image with the calculated dimensions, centered within the 50x50 box 
					// doc.addImage(logoDataUrl, 'PNG', 10 + xOffset, 10 + yOffset, width, height);
					// Add company Name, SSN and a header line
					doc.setFontSize(14);
					// doc.setTextColor("#2d3a5e");
					doc.text(this.customerInformation.name, textXPosition, 20);
					doc.setFontSize(12);
					doc.text(this.customerInformation.identificationNumber, textXPosition, 30);
					doc.setFontSize(10);
					doc.text(project.name, textXPosition, 40);
					doc.text(project.key, textXPosition, 50);
					this.generatePdfBody(project, doc);
				}
			}
		},
		generatePdfBody(project, doc) {
			doc.setFontSize(10);
			doc.line(10, 72, 200, 72); // Draw a line

			// Headers
			const headers = ["Tól", "Verð", "Verð m/vsk"];
			const startX = 10;
			let currentYPosition = 80;
			// Adjust this for spacing between headers 
			headers.forEach((header, index) => {
				const headerPositions = [startX, startX + 120, startX + 160];
				doc.text(header, headerPositions[index], currentYPosition); 
			});

			doc.line(10, currentYPosition + 5, 200, currentYPosition + 5); 
			// Line below headers
			currentYPosition += 15;

			// Starting Y position for the text
			// let currentYPosition = 80; 
			const pageHeight = doc.internal.pageSize.height;
			const bottomMargin = 10; // Margin at the bottom of the page

			// Add the information from the collapse item
			// You'll need to format this content appropriately
			const projectInfo = this.formatProjectInfoForPDF(project);
			const lines = projectInfo.split('\n');

			// Add each line of the project info to the PDF
			lines.forEach(line => {
				// Check if the line is not a placeholder for the horizontal line
				if (line !== '---') {
					// Check if the current position exceeds the page height
					if (currentYPosition >= pageHeight - bottomMargin) {
						doc.addPage();
						currentYPosition = 10; // Reset Y position for the new page
					}
					doc.text(line, 10, currentYPosition);
					currentYPosition += 5; // Increase Y position for the next line
				} 
				else {
					// Ensure there's enough space for the line
					if (currentYPosition >= pageHeight - bottomMargin) {
						doc.addPage();
						currentYPosition = 10; // Reset Y position for the new page
					}
					doc.line(10, currentYPosition - 5, 200, currentYPosition - 5); // Draw a line
					currentYPosition += 5; // Increase Y position for the next line
				}
			});

			// Save the PDF
			doc.save(`${project.name}.pdf`);
		},
		formatProjectInfoForPDF(project) {
			let formattedText = '';
			const pageWidth = 210; // A4 width in mm
			const rightMargin = 10; // Right margin in mm
			const doc = new jsPDF();

			// Set the font to Times New Roman
			doc.setFont('times', 'normal');
			doc.setFontSize(10);

			let totalRevenue = project.totalRevenue;
			const pricePosition = 130;          // Align with the "Verð" header
			const priceWithVATPosition = 170;   // Align with the "Verð m/VSK" header

			for (let tool of project.tools) {
				// Tool name
				formattedText += `${tool.reservations[0].tool.name}\n`;

				// Reservations for the tool
				tool.reservations.forEach(reservation => {
					let reservedDate = new Date(reservation.reservedTimestamp).toLocaleDateString();
					let unreservedDate = new Date(reservation.unreservedTimestamp).toLocaleDateString();
					let priceCalculation = (reservation.totalPrice / 1.24).toFixed(2) + " kr";
					let priceWithVAT = reservation.totalPrice.toFixed(2) + " kr";
					let dates = `     ${reservedDate} - ${unreservedDate}`;

					// Adjust positions based on character lengths
					const priceTextWidth = doc.getStringUnitWidth(priceCalculation) * doc.internal.getFontSize() / doc.internal.scaleFactor;
					const priceWithVATTextWidth = doc.getStringUnitWidth(priceWithVAT) * doc.internal.getFontSize() / doc.internal.scaleFactor;
					const adjustedPricePosition = pricePosition - priceTextWidth;
					const adjustedPriceWithVATPosition = priceWithVATPosition - adjustedPricePosition - priceWithVATTextWidth;

					// Print formatted line with proper alignment
					formattedText += `${dates.padEnd(adjustedPricePosition, ' ')}`
								+ `${priceCalculation.padEnd(adjustedPriceWithVATPosition + 5, ' ')}`
								+ `${priceWithVAT}\n`;
				});

				// Empty line between each tool
				formattedText += '\n';
			}

			// Placeholder for the line above the total revenue
			formattedText += '---\n';
			let revenueWithoutVAT = (totalRevenue / 1.24).toFixed(2) + " kr";
			let totalWithVAT = totalRevenue.toFixed(2) + " kr";

			// Align totals with headers
			const totalTextWidthWithoutVAT = doc.getStringUnitWidth(revenueWithoutVAT) * doc.internal.getFontSize() / doc.internal.scaleFactor;
			const totalTextWidthWithVAT = doc.getStringUnitWidth(totalWithVAT) * doc.internal.getFontSize() / doc.internal.scaleFactor;
			const adjustedTotalPositionWithoutVAT = pricePosition - totalTextWidthWithoutVAT;
			const adjustedTotalPositionWithVAT = priceWithVATPosition - adjustedTotalPositionWithoutVAT - totalTextWidthWithVAT;

			formattedText += "Heildarupphæð     ".padEnd(adjustedTotalPositionWithoutVAT + 5, ' ') 
						+ revenueWithoutVAT.padEnd(adjustedTotalPositionWithVAT + 5, ' ') 
						+ totalWithVAT;

			return formattedText;
		},
		updateIsMobile() {
			this.isMobile = window.innerWidth <= 768;
		}
	}
};
</script>
<style scoped>
	.calendar {
		padding-bottom: 15px;
	}
	.item-tool {
		padding-left: 20px;
		margin-bottom: 10px;
	}

	.item-tool-dates {
		padding-left: 40px;
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.item-tool-dates-amount {
		float: right;
		padding-right: 50px;
	}

	.download-button {
		width: 100%
	}

	.project-details { 
		display: block;
	}
	.newline { 
		display: block;
	}
</style>
